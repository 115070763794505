import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import NotFound from "../notFound"
import {SaleService} from "../../app/services/SaleService"
import {OrderIndexContainer} from "./orderId/OrderIndexContainer"
import {
  OrderAdditionalInfo,
  OrderCustomerInfo,
  OrderDeliveryInfo,
  OrderHistory,
  OrderPaymentInfo,
  OrderService,
  OrderShipmentInfo,
  OrderShipmentSupplierInfo,
  OrderDetail,
  IOrderAction,
} from "../../app/interfaces/salesInterfaces"
import requestData from "../../app/utils/requestData"
import {OrderControlService} from "../../app/services/OrderControlService"
import {EventLogService} from "../../app/services/EventLogService"
import {OrderEvent, OrderEventItems} from "../../app/interfaces/eventLogInterfaces"
import {useUserContext} from "../../app/context/userContext"
import {OrderPriceReview} from "../../app/interfaces/orderControlnterfaces"
import {ITableResponse} from "../../app/interfaces/http"

const OrderIndexPage: React.FC = () => {
  const {orderId} = useParams()
  const [order, setOrder] = useState<OrderDetail | null>(null)
  const [customerInfo, setCustomerInfo] = useState<OrderCustomerInfo | null>(null)
  const [orderServices, setOrderServices] = useState<OrderService[] | null>(null)
  const [orderHistory, setOrderHistory] = useState<any | null>(null)
  const [orderEvent, setOrderEvent] = useState<any | null>(null)
  const [orderActions, setOrderActions] = useState<IOrderAction[]>([])
  const [orderPayment, setOrderPayment] = useState<OrderPaymentInfo | null>(null)
  const [additionalInfo, setAdditionalInfo] = useState<OrderAdditionalInfo | null>(null)
  const [deliveryInfo, setDeliveryInfo] = useState<OrderDeliveryInfo | null>(null)
  const [shipmentInfo, setShipmentInfo] = useState<OrderShipmentSupplierInfo | null>(null)
  const [shipmentsMap, setShipmentsMap] = useState<OrderShipmentInfo | null>(null)
  const [priceReview, setPriceReview] = useState<OrderPriceReview | null>(null)
  const {userInfo} = useUserContext()
  const [allowedToView, setAllowedToView] = useState(false)
  const audio = new Audio("/music_new.mp3")

  useEffect(() => {
    if (orderId) {
      fetchOrderInfo()
    }
    if (
      userInfo?.roles.find(
        (role) =>
          role.role_name === "ROOT" ||
          role.role_name === "Управляющий продажами" ||
          role.role_name === "Менеджер розницы",
      )
    ) {
      setAllowedToView(true)
    }
  }, [])

  const playAudio = async () => {
    audio.autoplay = true
    await audio.play()
    setTimeout(() => {
      audio.pause()
    }, 7000)
  }

  const fancyFunction = () => {
    document.querySelectorAll("*").forEach((element) => {
      element.addEventListener("mouseover", function () {
        // @ts-ignore
        event.stopPropagation()
        // @ts-ignore
        this.style.background = "linear-gradient(to bottom, red, orange, yellow, green, blue, indigo, violet)"
      })

      element.addEventListener("mouseout", function () {
        // @ts-ignore
        this.style.background = ""
      })
    })
  }

  const fetchOrderInfo = async () => {
    const orderInfo = await requestData(SaleService.getOrderDetails, orderId)
    if (orderInfo) {
      setOrder(orderInfo)
      Promise.all([
        fetchCustomerInfo(orderInfo.order_session),
        fetchOrderHistory(orderInfo.order_session),
        fetchPaymentInfo(orderInfo.order_session),
        fetchOrderAdditionalInfo(orderInfo.order_session),
        fetchDeliveryInfo(orderInfo.order_session),
        fetchOrderDeliveryAndShipmentInfo(orderInfo.order_session),
        fetchOrderServices(orderInfo.order_session),
        fetchOrderShipmentsMap(orderId!!),
        fetchOrderEvent(orderInfo.order_id),
        fetchPriceReview(orderInfo.order_session),
        fetchOrderActions(orderInfo.order_id),
      ])
    }
  }

  const fetchCustomerInfo = async (orderSession: string) => {
    const customerInfo = await requestData<OrderCustomerInfo>(SaleService.getCustomerInfoByOrderSession, orderSession)
    if (customerInfo) {
      setCustomerInfo(customerInfo)
    }
  }

  const fetchOrderServices = async (orderSession: string) => {
    const orderServices = await requestData<OrderService[]>(SaleService.getOrderServicesByOrderSession, orderSession)
    if (orderServices) {
      setOrderServices(orderServices)
    }
  }

  const fetchOrderHistory = async (orderSession: string) => {
    const orderHistory = await requestData<OrderHistory[]>(SaleService.getOrderHistoryByOrderSession, orderSession)
    if (orderHistory) {
      setOrderHistory(orderHistory)
    }
  }
  const fetchOrderEvent = async (orderId: number) => {
    const orderEvent = await requestData<OrderEventItems>(EventLogService.getOrderEventsByOrderId, orderId)
    if (orderEvent) {
      setOrderEvent(orderEvent.items)
    }
  }
  const fetchOrderActions = async (orderId: number) => {
    const orderEvent = await requestData<ITableResponse<IOrderAction[]>>(EventLogService.getOrderActions, orderId)
    if (orderEvent) {
      setOrderActions(orderEvent.items)
    }
  }

  const fetchPaymentInfo = async (orderSession: string) => {
    const paymentInfo = await requestData<OrderPaymentInfo>(SaleService.getOrderPaymentInfoByOrderSession, orderSession)
    if (paymentInfo) {
      setOrderPayment(paymentInfo)
    }
  }

  const fetchOrderAdditionalInfo = async (orderSession: string) => {
    const additionalInfo = await requestData<OrderAdditionalInfo>(
      SaleService.getOrderAdditionalInfoByOrderSession,
      orderSession,
    )
    if (additionalInfo) {
      setAdditionalInfo(additionalInfo)
    }
  }

  const fetchDeliveryInfo = async (orderSession: string) => {
    const deliveryInfo = await requestData<OrderDeliveryInfo>(
      SaleService.getOrderDeliveryInfoByOrderSession,
      orderSession,
    )
    if (deliveryInfo) {
      setDeliveryInfo(deliveryInfo)
    }
  }

  const fetchOrderDeliveryAndShipmentInfo = async (orderSession: string) => {
    const shipmentInfo = await requestData<OrderShipmentSupplierInfo>(
      SaleService.getOrderCustomerAndShipmentInformation,
      orderSession,
    )
    if (shipmentInfo) {
      setShipmentInfo(shipmentInfo)
    }
  }

  const fetchOrderShipmentsMap = async (orderId: string) => {
    const shipmentsMap = await requestData<OrderShipmentInfo>(OrderControlService.getShipmentsMap, orderId)
    if (shipmentsMap) {
      setShipmentsMap(shipmentsMap)
    }
  }

  const fetchPriceReview = async (orderSession: string) => {
    const priceReview = await requestData<OrderPriceReview>(OrderControlService.getOrderPriceReview, orderSession)
    if (priceReview) {
      setPriceReview(priceReview)
    }
  }

  if (!orderId || isNaN(parseInt(orderId)) || !allowedToView) return <NotFound />
  return (
    <>
      {order && (
        <OrderIndexContainer
          order={order}
          orderProducts={order.order_products}
          orderServices={orderServices}
          orderHistory={orderHistory}
          orderEvent={orderEvent}
          orderActions={orderActions}
          orderPaymentInfo={orderPayment}
          customerInfo={customerInfo}
          orderAdditionalInfo={additionalInfo}
          orderDeliveryInfo={deliveryInfo}
          orderShipmentSupplierInfo={shipmentInfo}
          orderShipmentMap={shipmentsMap}
          fetchServicesAgain={fetchOrderInfo}
          priceReview={priceReview}
        />
      )}
    </>
  )
}

export default OrderIndexPage
